import React from 'react'
import photo from '../images/favourite-things/007817.jpg'
import Layout from '../layouts'

const FavouriteThings = () => (
  <Layout>
    <section>
      <h1>Favourite Things</h1>
      <figure>
        <img src={photo} alt="" />
      </figure>
      <article>
        <p>A few of my favourite things….</p>
        <p>
          I regularly guide wildlife photography trips for Steppes Travel, who
          offer some exceptional wildlife experiences whilst supporting local
          conservation and community projects around the world and I’m looking
          forward to guiding many{' '}
          <a
            href="https://www.steppestravel.com/wildlife-group-tours/sue-flood"
            target="_blank"
            rel="noreferrer"
          >
            trips for Steppes in 2021 and beyond!
          </a>
          .
        </p>
        <p>
          I'm a great fan of the{' '}
          <a
            href="http://www.bushcampcompany.com/"
            target="_blank"
            rel="noreferrer"
          >
            {' '}
            Bushcamp Company
          </a>{' '}
          in the South Luangwa valley, Zambia.{' '}
          <a
            href="https://bushcampcompany.com/"
            target="_blank"
            rel="noreferrer"
          >
            Mfuwe Lodge
          </a>{' '}
          has been voted the country’s best lodge several times and their small
          camps are superb! I lead photo tours here for Steppes Travel and also
          enjoy guiding private safaris at this location as the wildlife, lodge
          and people are all fabulous.
        </p>
        <p>
          I've just been kitting myself out with some{' '}
          <a href="http://www.paramo.co.uk/" target="_blank" rel="noreferrer">
            {' '}
            great Paramo kit
          </a>{' '}
          for my forthcoming adventures - which will take me from the tropics to
          the polar regions
        </p>
        <p>
          I love my Zeiss Victory HT 8 x 42 binoculars. Bright, light and sharp!
          I also use some{' '}
          <a
            href="http://www.zeiss.co.uk/camera-lenses/en_gb/camera_lenses.html"
            target="_blank"
            rel="noreferrer"
          >
            stunning Zeiss lenses, including the Otus 1.4/55mm, and Distagon T*
            2.8/15mm
          </a>
          .
        </p>
        <p>
          I’ve use an excellent lightweight but sturdy{' '}
          <a
            href="http://www.manfrotto.co.uk/professional-roller-bag-70"
            target="_blank"
            rel="noreferrer"
          >
            Manfrotto Professional Roller Bag 70
          </a>{' '}
          . Perfect for carry-on at the airport, but strong enough for me not to
          have to worry if I end up having to gate-check my cameras on some
          small planes. Plus it holds a LOT, including my 17” laptop. I am a big
          fan of{' '}
          <a
            href="http://www.manfrotto.com/products/bags/backpack/prolight"
            target="_blank"
            rel="noreferrer"
          >
            Manfrotto’s camera bags
          </a>{' '}
          and use the{' '}
          <a
            href="http://www.manfrotto.com/products/bags/backpack/pro-light-video-backpack-pro-v-610-pl"
            target="_blank"
            rel="noreferrer"
          >
            Pro Light Video Backpack: Pro-V-610 PL
          </a>{' '}
          or the{' '}
          <a
            href="http://www.manfrotto.com/products/bags/backpack/pro-light-video-backpack-pro-v-410-pl"
            target="_blank"
            rel="noreferrer"
          >
            Pro Light Video Backpack: Pro-V-410 PL
          </a>{' '}
          - perfect for when I'm on a walking safari, or trekking across the ice
          to photograph emperor penguins!
        </p>
        <p>
          I am fortunate to work with the wonderful Stephen Johnson of{' '}
          <a
            href="http://www.copyrightimage.co.uk/"
            target="_blank"
            rel="noreferrer"
          >
            {' '}
            Copyrightimage Ltd
          </a>
          . Stephen is a brilliant editor, retoucher and trainer and I highly
          recommend him. He edits and prepares my shots for Getty Images, and
          prepared all my files for my book "Cold Places”.
        </p>
        <p>
          <a href="http://www.gitzo.co.uk/" target="_blank" rel="noreferrer">
            {' '}
            Gitzo
          </a>{' '}
          make the best tripods! I use the Gizto Systematic Series 4 carbon
          tripod GT4552TS with the Gitzo series 5 quick-release ball-head -
          packs down small, and is super-strong - perfect for my Canon 600mm.
        </p>
        <p>
          I'm very proud to have been asked to be part of the{' '}
          <a
            href="http://www.manfrottoschoolofxcellence.com/"
            target="_blank"
            rel="noreferrer"
          >
            {' '}
            Manfrotto School of Xcellence
          </a>
          . Their website has some great interviews, top tips, profiles of some
          very talented photographers and more. I'll be doing some webinars for
          them later in the year so watch this space!
        </p>
        <p>
          Clifton Cameras are very helpful if you need new camera kit. You can
          see some of{' '}
          <a
            href="https://www.cliftoncameras.co.uk/Blog/Sue-Flood-Journals"
            target="_blank"
            rel="noreferrer"
          >
            my photo journals here
          </a>
          .
        </p>
      </article>
    </section>
  </Layout>
)

export default FavouriteThings
